<template>
  <div class="jumbotron p-5 rounded-lg" style="margin-bottom: 2em">
    <h1 class="display-4">Local Services</h1>
  </div>

  <div class="container">

    <!--TAXES-->
    <div class="row text-center">
      <h3><i class="fas fa-search-dollar"></i> Tax Information</h3>
      <div class="col-sm">
        <a href="https://www.irs.gov/" target="_blank">
          <div class="card">
            <div class="card-body"><h5 class="card-title">IRS</h5></div>
          </div>
        </a>
      </div>
      <div class="col-sm">
        <a href="https://www.michigan.gov/taxes" target="_blank">
          <div class="card">
            <div class="card-body"><h5 class="card-title">Michigan Taxes</h5></div>
          </div>
        </a>
      </div>
    </div>

    <hr>

    <!--GOVERNMENT-->
    <div class="row text-center">
      <h3><i class="fas fa-university"></i> MI Government Officials</h3>
      <div class="col-sm">
        <a href="https://senate.michigan.gov/" target="_blank">
          <div class="card">
            <div class="card-body"><h5 class="card-title">Senate</h5></div>
          </div>
        </a>
      </div>
      <div class="col-sm">
        <a href="https://www.house.mi.gov/" target="_blank">
          <div class="card">
            <div class="card-body"><h5 class="card-title">House of Representatives</h5></div>
          </div>
        </a>
      </div>
    </div>

    <hr>

    <!--HEALTH INSURANCE-->
    <div class="row text-center">
      <h3><i class="far fa-hospital"></i> Health Insurance Information</h3>
      <div class="col-sm d-flex align-items-stretch">
        <a href="https://www.healthcare.gov/" target="_blank">
          <div class="card">
            <div class="card-body"><h5 class="card-title">Healthcare.gov</h5></div>
          </div>
        </a>
      </div>
      <div class="col-sm d-flex align-items-stretch">
        <a href="https://www.consumerreports.org/health-insurance/guide-to-health-insurance/" target="_blank">
          <div class="card">
            <div class="card-body"><h5 class="card-title">Insurance Guide</h5></div>
          </div>
        </a>
      </div>
      <div class="col-sm d-flex align-items-stretch">
        <a href="http://www.aarp.org/health/health-insurance/" target="_blank">
          <div class="card">
            <div class="card-body"><h5 class="card-title">AARP Fact Sheet</h5></div>
          </div>
        </a>
      </div>
      <div class="col-sm d-flex align-items-stretch">
        <a href="https://www.irs.gov/Affordable-Care-Act/Affordable-Care-Act-Tax-Provisions" target="_blank">
          <div class="card">
            <div class="card-body"><h5 class="card-title">ACA Tax Provisions</h5></div>
          </div>
        </a>
      </div>
      <div class="col-sm d-flex align-items-stretch">
        <a href="https://www.michigan.gov/difs/0,5269,7-303-12902_35510_92612---,00.html" target="_blank">
          <div class="card">
            <div class="card-body"><h5 class="card-title">Consumer Assistance Program</h5></div>
          </div>
        </a>
      </div>
    </div>

    <hr>

    <!--SENIORS-->
    <div class="row text-center">
      <h3><i class="fas fa-users"></i> Seniors</h3>
      <div class="col-sm-4">
        <a
          href="https://www.saginawcounty.com/departments/commission-on-aging/saginaw-county-senior-centers/"
          target="_blank">
          <i class="fas fa-house-user fa-5x" aria-hidden="true"></i>
        </a>
        <p>Senior Centers</p>
      </div>
      <div class="col-sm-4">
        <a href="https://www.saginawcounty.com/departments/commission-on-aging/services/"
           target="_blank">
          <i class="fas fa-building fa-5x" aria-hidden="true"></i>
        </a>
        <p>Commission on Aging</p>
      </div>
      <div class="col-sm-4">
        <a href="https://eldercare.acl.gov/Public/Index.aspx" target="_blank">
          <i class="fas fa-bullseye fa-5x" aria-hidden="true"></i>
        </a>
        <p>Eldercare Locator</p>
      </div>
    </div>

    <hr>

    <!--UNEMPLOYMENT-->
    <br>
    <div class="row text-center">
      <h3><i class="fas fa-user" aria-hidden="true"></i> Unemployed?</h3>
      <div class="col-sm-4">
        <a href="http://www.michiganworks.org/" target="_blank">
          <img src="../localServices/images/michigan_works.jpg" class="img-responsive" style="width:100%"
               alt="MI Works">
        </a>
        <p>MI Works</p>
      </div>
      <div class="col-sm-4">
        <a href="http://www.michigan.gov/helpinghand" target="_blank">
          <img src="../localServices/images/helping_hand.png" class="img-responsive" style="width:100%"
               alt="Helping Hand">
        </a>
        <p>Helping Hand</p>
      </div>
      <div class="col-sm-4 image-center">
        <a href="http://www.unitedwaysaginaw.org/index.php?pr=Where_to_Turn_guide" target="_blank">
          <img src="../localServices/images/united_way.png" class="img-responsive" style="width:100%" alt="United Way">
        </a>
        <p>United Way</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'LocalServices'
}
</script>

<style scoped>
.jumbotron {
  background-color: #7a9c4e;
  color: #FFFFFF;
  text-align: center;
}

.card-body {
  border-radius: .25em;
  background-color: #4e6738;
  color: #FFFFFF;
}
</style>
